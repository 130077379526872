export const SETLOADING = 'SETLOADING';
export const SETDEPOSITLOADING = 'SETDEPOSITLOADING';
export const SETWITHDRAWLOADING = 'SETWITHDRAWLOADING';
export const SETWITHDRAWMODAL = 'SETWITHDRAWMODAL';
export const SETTOKEN = 'SETTOKEN';
export const SETAUTHERROR = 'SETAUTHERROR';
export const SETEMAILMODAL = 'SETEMAILMODAL';
export const SETSHOWDRAWER = 'SETSHOWDRAWER';
export const SETUSERDATA = 'SETUSERDATA';
export const SETDEPOSITMODAL = 'SETDEPOSITMODAL';
export const SETWALLETLOADING = 'SETWALLETLOADING';
export const SETWALLETMODAL = 'SETWALLETMODAL';