import React, { useState } from 'react';

//styles
import classes from '../withdraw.module.css';

//components
import { Input } from '../../../components';
import Wallet from './Wallet';

//images
import bitcoin from '../../../assets/crypto/bitcoin.png';
import ethereum from '../../../assets/crypto/ethereum.png';
import dogecoin from '../../../assets/crypto/dogecoin.png';
import binance from '../../../assets/crypto/binance.png';
import { wallets } from '../../../data/coins';

function WithdrawalForm({withdraw, data}) {
    //UI state
    const [amount, setAmount] = useState(50);
    // const [selected, setSelected] = useState({name: 'Bitcoin', address: data.bitcoin_address});

    const [selectedWallet, setSelectedWallet] = useState('');
    const [walletAddress, setWalletAddress] = useState('');

    return (
        <div className={classes.WithdrawalForm}>
            <h3>Withdrawal Form</h3>
            <div className={classes.form_container}>
                <Input title={'Amount'} type={'number'} onChange={e => setAmount(e)} value={50}  />
                <div className=''>
                    <p>Select platform</p>
                    <select className='h-[45px] w-full bg-[#fbfbfb] border border-[#b5b5b5] rounded-[7px] px-[10px]' onChange={e => setSelectedWallet(e.target.value)}>
                        {wallets.map((wallet, index) => (
                            <option 
                                value={wallet.name}
                                selected={wallet.name === "USDT TRC20"}
                                key={index}
                            >
                                {wallet.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className=''>
                    <Input 
                        title={'Wallet address'} 
                        type={"text"} 
                        onChange={e => setWalletAddress(e)} 
                        value={walletAddress}  
                    />
                </div>
            </div>
            <button 
                onClick={() => withdraw({amount, selected: {name: selectedWallet, address: walletAddress}})}
                disabled={selectedWallet.length === 0 && walletAddress.length === 0}
                className='disabled:opacity-40'
            >Withdraw</button>
        </div>
    )
}

export default WithdrawalForm