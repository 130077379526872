import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//styles
import classes from './register.module.css';

//components
import { Header, Input, Loader, Password, SelectInput, SideBar } from "../../components";

//redux actions
import { register } from "../../redux/actions";
import EmailModal from "./components/EmailModal";
import { countries } from "../../data";
import { FaCircleCheck } from "react-icons/fa6";

import registersvg from '../../assets/auth_svg.svg';

const registrationReasons = [
    'Free to Register',
    'Referral Bonus',
    'Free Advisory Call',
    'No Country Restriction',
    'Easy Registration Steps'
]

const backdrop_img = 'https://cdn01.alison-static.net/careers/career/Stock%20Trader.jpg'

const Register = () => {
    //initialize
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //form state
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [country, setCountry] = useState('United States of America');
    const [password, setPassword] = useState();

    //UI state
    const [showSidebar, setShowSidebar] = useState(false);

    //redux state
    const loading = useSelector(state => state.mainReducer.loading);
    const emailModal = useSelector(state => state.mainReducer.emailModal);
    const authError = useSelector(state => state.authReducer.authError);

    const handleRegister = () => {
        dispatch(register(name, email, password, country));
    };

    const container = (
        <div>
            <div className={classes.formContainer}>
                <h2 className="text-2xl font-semibold text-black/70">Create Account</h2>
                <p className={classes.intro_text}>Create an account and get started</p>
                <div className={classes.inputsContainer}>
                    <Input title={'Fullname'} type='text' onChange={e => setName(e)} value={name} />
                    <Input title={'Email'} type='email' onChange={e => setEmail(e)} value={email} />
                    <SelectInput title={'Country'} onChange={e => setCountry(e)} data={countries} value={'United States of America'} />
                    <Password onChange={e => setPassword(e)} value={password} />
                    {authError && <p style={{margin: 0, color: 'red', fontSize: 14}}>{authError}</p>}
                </div>
                <button onClick={handleRegister}>Create account</button>
                <p className={classes.loginLink} onClick={() => navigate('/login')} >Already have an account? <span>Login instead</span></p>
            </div>
        </div>
    )

    return(
        <div>
            <Header active={'Register'} open={() => setShowSidebar(true)} />
            <div className={`${classes.container} flex flex-col md:flex-row gap-5 md:items-center`}>
                <div className="flex-1 p-5 md:p-10 bg-white">
                    <h1 className="text-xl font-medium">Register a new account</h1>
                    <p className="text-black/70 text-sm mt-2">To join Virex Trade, you first have to register for a new account. To register fill in form details and click the "Create account" button</p>
                    <div className="flex flex-col gap-3 mt-5">
                        {registrationReasons.map((reason, index) => (
                            <div className="flex items-center gap-2 text-black/70" key={index}>
                                <FaCircleCheck /><p className="text-sm">{reason}</p>
                            </div>
                        ))}
                    </div>
                    <img
                        src={registersvg}
                        alt=""
                        className="max-w-[250px] mt-5"
                    />
                </div>
                <div className="flex-1">
                    {loading ? <Loader /> : container }
                </div>
            </div>
            {emailModal && <EmailModal email={emailModal} />}
            {showSidebar && <SideBar close={() => setShowSidebar(false)}  />}
        </div>
    )
};

export default Register