import React, { useEffect, useState } from 'react';

const deposits = [
    [
        { name: "Luca", amount: "$1,420.56", cryptoPlatform: "BTC" },
        { name: "Sofia", amount: "$899.34", cryptoPlatform: "ETH" },
        { name: "Marek", amount: "$1,150.89", cryptoPlatform: "SOL" },
        { name: "Anika", amount: "$360.45", cryptoPlatform: "XLM" },
        { name: "Elena", amount: "$1,820.22", cryptoPlatform: "ADA" }
    ],
    [
        { name: "Tomáš", amount: "$1,075.34", cryptoPlatform: "SHIB" },
        { name: "Niko", amount: "$765.89", cryptoPlatform: "DOGE" },
        { name: "Freja", amount: "$1,320.00", cryptoPlatform: "BTC" },
        { name: "Jana", amount: "$1,999.99", cryptoPlatform: "ETH" },
        { name: "Lars", amount: "$415.78", cryptoPlatform: "SOL" }
    ],
    [
        { name: "Zuzana", amount: "$520.10", cryptoPlatform: "XRP" },
        { name: "Amélie", amount: "$1,780.67", cryptoPlatform: "SHIB" },
        { name: "Filip", amount: "$1,145.45", cryptoPlatform: "XLM" },
        { name: "Bianca", amount: "$630.50", cryptoPlatform: "DOGE" },
        { name: "Erik", amount: "$1,450.12", cryptoPlatform: "ADA" }
    ],
    [
        { name: "Greta", amount: "$1,760.00", cryptoPlatform: "SOL" },
        { name: "Kamil", amount: "$940.34", cryptoPlatform: "ETH" },
        { name: "Astrid", amount: "$480.23", cryptoPlatform: "XRP" },
        { name: "Mateo", amount: "$1,380.56", cryptoPlatform: "SHIB" },
        { name: "Hana", amount: "$795.45", cryptoPlatform: "BTC" }
    ]
];

const withdrawals = [
    [
        { name: "Viktor", amount: "$1,800.45", cryptoPlatform: "SOL" },
        { name: "Petra", amount: "$700.67", cryptoPlatform: "ETH" },
        { name: "Jakub", amount: "$1,420.34", cryptoPlatform: "XRP" },
        { name: "Martina", amount: "$2,510.89", cryptoPlatform: "SHIB" },
        { name: "Stefan", amount: "$3,000.00", cryptoPlatform: "BTC" }
    ],
    [
        { name: "Lucia", amount: "$2,840.78", cryptoPlatform: "XRP" },
        { name: "Oscar", amount: "$1,290.00", cryptoPlatform: "SHIB" },
        { name: "Milena", amount: "$760.45", cryptoPlatform: "XLM" },
        { name: "Theo", amount: "$2,330.12", cryptoPlatform: "DOGE" },
        { name: "Isabelle", amount: "$2,670.90", cryptoPlatform: "ADA" },
    ],
    [
        { name: "Bartosz", amount: "$1,800.89", cryptoPlatform: "SHIB" },
        { name: "Klara", amount: "$750.67", cryptoPlatform: "DOGE" },
        { name: "Jasper", amount: "$2,290.45", cryptoPlatform: "BTC" },
        { name: "Adriana", amount: "$870.34", cryptoPlatform: "ETH" },
        { name: "Magnus", amount: "$1,950.67", cryptoPlatform: "SOL" },
    ],
    [
        { name: "Lukas", amount: "$1,890.56", cryptoPlatform: "BTC" },
        { name: "Svenja", amount: "$2,740.34", cryptoPlatform: "ETH" },
        { name: "Dario", amount: "$900.12", cryptoPlatform: "SOL" },
        { name: "Ivana", amount: "$2,540.78", cryptoPlatform: "XLM" },
        { name: "Elisabeth", amount: "$1,340.22", cryptoPlatform: "ADA" },
    ],
]

function Transactions() {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          setCounter((prev) => (prev < 3 ? prev + 1 : 0));
        }, 5500);
    
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <div className='w-full bg-blue-100 py-10 px-[50px] flex flex-col md:flex-row gap-10'>
            <div className='md:flex-1 text-center'>
                <h1 className='text-2xl font-semibold'>Investments</h1>
                <div className='w-full mt-5 bg-white rounded-lg overflow-hidden'>
                    <div className='w-full grid grid-cols-3 gap-1 text-black/80 text-sm text-center font-medium py-3 bg-[#eee]'>
                        <p>Name</p>
                        <p>Amount</p>
                        <p>Payment method</p>
                    </div>
                    <div className='flex flex-col w-full bg-white'>
                        {deposits[counter].map((transaction, index) => (
                            <div className='w-full grid grid-cols-3 gap-1 text-black/70 text-sm text-center font-medium py-5 border-b border-black/10' key={index}>
                                <p>{transaction.name}</p>
                                <p className='text-green-500'>{transaction.amount}</p>
                                <p>{transaction.cryptoPlatform}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='md:flex-1 text-center'>
                <h1 className='text-2xl font-semibold'>Withdrawals</h1>
                <div className='w-full mt-5 bg-white rounded-lg overflow-hidden'>
                    <div className='w-full grid grid-cols-3 gap-1 text-black/80 text-sm text-center font-medium py-3 bg-[#eee]'>
                        <p>Name</p>
                        <p>Amount</p>
                        <p>Payment method</p>
                    </div>
                    <div className='flex flex-col w-full bg-white'>
                        {withdrawals[counter].map((transaction, index) => (
                            <div className='w-full grid grid-cols-3 gap-1 text-black/70 text-sm text-center font-medium py-5 border-b border-black/10' key={index}>
                                <p>{transaction.name}</p>
                                <p className='text-red-500'>{transaction.amount}</p>
                                <p>{transaction.cryptoPlatform}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Transactions