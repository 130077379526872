import React from 'react';
import { useNavigate } from 'react-router-dom';
import { percentage } from '../../../../helpers';

//styles
import classes from './plans.module.css';

const plan1 = ['https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrnhty-u2Ux_cuM1yRVxN5mLrrTlpn0Y5kYw&usqp=CAU', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSey92a9obZmUrKjrJVvex4Zpz17uYHXxcpRQ&usqp=CAU', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSAPmEv0swP9Mwv3lhmuomnYj057jV99FgxVciAoA-kC2eipgXw-iRtGtE7JYr7BWc-tE&usqp=CAU']
const plan2 = ['https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjWY5CbCnxjnzKZuN1M8XW45_kDymD2ane32ywSeCjkM24iEqBMU3zISCq6t65u0-4WDQ&usqp=CAU', 'https://media.istockphoto.com/id/1278978817/photo/portrait-of-happy-mature-man-smiling.jpg?b=1&s=170667a&w=0&k=20&c=-G50QM8y40HoN7k6lh1TL3ZFxASPYpIs_DJxo5FkEK0=', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdMDmOdP6ZFjB2JCd0Pr-RX82XCCnKj5lx7Q&usqp=CAU'];
const plan3 = ['https://thumbor.forbes.com/thumbor/fit-in/x/https://www.forbes.com/advisor/wp-content/uploads/2022/04/dougwhiteman-scaled.jpeg', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEZNAMTsV7s2WDnOFZsYVfORW-ec6bABbdI8hYAG0x1zd10j-AArrhq6Jenro56tvodZo&usqp=CAU', 'https://www.uchastings.edu/wp-content/uploads/2022/10/julie-parker.jpg'];

function Plans({data}) {
    //initialize
    const navigate = useNavigate();
    console.log(data)

    const Plan = ({icon, color, name, writeUp, range, amount, amountRange}) => {
        const originalLength = percentage(amount, amountRange[0], amountRange[1]);

        let length;
        if(originalLength < 10){
            length = `5%`
        }else{
            length = `${originalLength}%`
        };

        console.log(length, name)
        return(
            <div className={classes.Plan}>
                <div className={classes.top}>
                    <div className={classes.icon} style={{backgroundColor: color}}>{icon}</div>
                    <div className={classes.name}>{name}</div>
                </div>
                <div className={classes.intro}>{writeUp}</div>
                <div className={classes.main}>
                    <div className={classes.pole}> <div style={{backgroundColor: color, width: length}} /> </div>
                    <div className={classes.amount}>
                        <div>{range[0]}</div>
                        <div>{range[1]}</div>
                    </div>
                </div>
                <div className={classes.bottom}>
                    <button onClick={() => navigate('/deposit')} >Invest</button>
                </div>
            </div>
        )
    };
    
    return (
        <div className={classes.container}>
            <h2>Plans</h2>
            <div className={classes.mainContainer}>
                <Plan 
                    icon={'👋'} 
                    color={'cyan'} 
                    name={'Starter'} 
                    amountRange={[50, 99]} 
                    range={['$50', '$99']} 
                    amount={data.starter_plan}
                    writeUp={'Invest a minumum of $50 and earn at an interest of 7% after 24 hours'} 
                />
                <Plan 
                    icon={'🚀'} 
                    color={'cyan'} 
                    name={'Basic'} 
                    amountRange={[100, 299]} 
                    range={['$100', '$299']} 
                    amount={data.basic_plan}
                    writeUp={'Invest a minumum of $100 and earn at an interest of 10% after 24 hours'} 
                />
                <Plan 
                    icon={'❄️'} 
                    color={'cyan'} 
                    name={'Silver'} 
                    amountRange={[300, 599]} 
                    range={['$300', '$599']} 
                    amount={data.silver_plan}
                    writeUp={'Invest a minumum of $300 and earn at an interest of 20% after 16 hours'} 
                />
                <Plan 
                    icon={'🔥'} 
                    color={'cyan'} 
                    name={'Advanced'} 
                    amountRange={[600, 999]} 
                    range={['$600', '$999']} 
                    amount={data.advanced_plan}
                    writeUp={'Invest a minumum of $600 and earn at an interest of 50% after 24 hours'} 
                />
                <Plan 
                    icon={'💎'} 
                    color={'cyan'} 
                    name={'Premium'} 
                    amountRange={[1000, 15000]} 
                    range={['$1,000', '$15,000']} 
                    amount={data.premium_plan}
                    writeUp={'Invest a minumum of $1,000 and earn at an interest of 100% after 48 hours'} 
                />
            </div>
        </div>
    )
};
export default Plans