import React from 'react'
import FaqItem from './FaqItem';

const questionsAndAnswers = [
    {
        question: 'What proves the legality of your company?',
        answer: "Our company is registered with the relevant regulatory authorities, and we have obtained the necessary license to operate"
    },  
    {
        question: "Does a hourly profit get paid directly to my account?",
        answer: "Yes , profit are gathered on your Virextrade.com account and you withdraw them whenever you want depending on the plan that you deposited into"
    },
    {
        question: "How many times can I reinvest in your company",
        answer: "You can make only 3 times Reinvestment on the STARTER PLAN while unlimited Reinvestment all other investment plans "
    },
    {
        question: "How many times can I withdraw money from Virextrade?",
        answer: "you can withdraw only 4 times from the STARTER plan while you can withdraw unlimited on other investment plans "
    },
    {
        question: "After I make a withdrawal request, when will the funds be available on my ecurrency account?",
        answer: "Your funds will be processed and sent to your external wallet within 5 to 10 minutes "
    },
    {
        question: "Who manages funds?",
        answer: "These funds are managed by a team of Virextrade investment experts"
    },
    {
        question: "How long does it take for my deposit to be added to my account?",
        answer: "Your deposit will  immediately be added to your account as soon as it has been received"
    },
    {
        question: "May I open several accounts in your program?",
        answer: "No! Multiple account isn’t allowed but you can make multiple deposit on same account."
    },
]

function FAQ() {
    return (
        <div className='w-full min-h-screen bg-[#f5f5f5] py-10 px-[20px] md:px-[50px] grid grid-cols-1 md:grid-cols-[1fr_2fr] gap-10'>
            <h1 className='text-2xl font-semibold text-black/90 pt-t md:pt-10'>Frequently Asked Questions</h1>
            <div className='flex flex-col gap-3'>
                {questionsAndAnswers.map((item, index) => (
                    <FaqItem
                        question={item.question}
                        answer={item.answer}
                        key={index}
                    />
                ))}
            </div>
        </div>
    )
}

export default FAQ