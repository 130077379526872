import React from 'react';
import { useNavigate } from 'react-router-dom';

//components
import { TransactionItem } from '../../../components';

//images
import emptyfolder from '../../../assets/empty-folder.png';

//styles
import classes from '../dashboard.module.css';

function Transactions({data, onSelect}) {
    //navigate
    const navigate = useNavigate();

    let container;

    if(data){
        container = (
            <div>
                {data.map((i, idx) => (
                    <TransactionItem
                        key={idx}
                        sn={idx + 1}
                        name={i.coin}
                        type={i.type}
                        amount={i.amount}
                        status={i.status}
                        date={i.date}
                        onSelect={() => onSelect(i)}
                    />
                ))}
            </div>
        )
    }else{
        container = (
            <div className={classes.empty_transactions}>
                <div>
                    <img src={emptyfolder} alt='emptyfolder' className='mx-auto' />
                    <p className={classes.empty_transactions_title}>No Transactions Yet</p>
                    <p className={classes.empty_transactions_details}>You haven't performed a transaction on this account yet</p>
                </div>
            </div>
        )
    };

    return (
        <div className={`${classes.transactions}`}>
            <div className={classes.transactions_top}>
                <h2>Transactions</h2>
                <button onClick={() => navigate('/transactions')}>View All</button>
            </div>
            <div className='w-full overflow-x-scroll'>
                <div className={`w-fit md:w-full mt-10 rounded-md bg-[#f5f5f5] overflow-hidden border-[#c4c4c4]`}>
                    <div className={`border-b border-[#c4c4c4] px-[20px] gap-[20px] grid py-5 grid-cols-[30px_200px_100px_100px_100px_100px] md:grid-cols-[30px_1fr_150px_150px_150px_150px]`}>
                        <div>SN</div>
                        <div>Transaction</div>
                        <div>Type</div>
                        <div>Amount</div>
                        <div>status</div>
                        <div className='hidden md:flex'>date</div>
                    </div>
                    {container}
                </div>
            </div>
        </div>
    )
}

export default Transactions