import React from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import classes from './plans.module.css';

function Plans() {
    //initialize
    const navigate = useNavigate();

    const Item = ({middle, logo, name, perks, minDeposit, maxDeposit}) => {
        return(
            <div className={`${classes.Item} ${middle && classes.middle}`}>
                <div className={classes.top}>
                    <div className={classes.icon}>{logo}</div>
                    <h3>{name}</h3>
                    <p>{perks}</p>
                </div>
                <div>
                    <div className={classes.detail}>Minimum Deposit <p>{minDeposit}</p></div>
                    <div className={classes.detail}>Maximum Deposit <p>{maxDeposit}</p></div>
                    <div className={classes.detail}>Principle Return <p>Yes</p></div>
                    <div className={classes.detail}>Withdrawal <p>5 - 10 mins</p></div>
                </div>

                <div className={classes.bottom}>
                    <button onClick={() => navigate('/register')}>Get Started</button>
                </div>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <h1> <span>Our</span> Investment Packages</h1>
            <p className={classes.intro}>Virex Trade has a wide variety of Investment Plans for you to choose from</p>
            <div className='mt-10 flex items-center justify-center gap-10 mx-auto max-w-[1200px] flex-wrap'>
                <Item 
                    logo={'👋'} 
                    name={'Starter'} 
                    perks={'7% after 24 hours'} 
                    minDeposit={'$50'} 
                    maxDeposit={'$99'} 
                />
                <Item 
                    logo={'🚀'} 
                    name={'Basic'} 
                    perks={'10% after 24 hours'} 
                    minDeposit={'$100'} 
                    maxDeposit={'$299'} 
                />
                <Item 
                    logo={'❄️'} 
                    name={'Silver'} 
                    perks={'20% after 16 hours'} 
                    minDeposit={'$300'} 
                    maxDeposit={'$599'} 
                />
                <Item 
                    logo={'🔥'} 
                    name={'Advanced'} 
                    perks={'50% after 24 hours'} 
                    minDeposit={'$600'} 
                    maxDeposit={'$999'} 
                />
                <Item 
                    logo={'💎'} 
                    name={'Premium'} 
                    perks={'100% after 48 hours'} 
                    minDeposit={'$1,000'} 
                    maxDeposit={'$15,000'} 
                />
            </div>
        </div>
    )
}

export default Plans