//images
import binance from '../assets/crypto/binance.png';
import bitcoin from '../assets/crypto/bitcoin.png';
import busd from '../assets/crypto/busd.svg';
import ethereum from '../assets/crypto/ethereum.png';
import usdt from '../assets/crypto/usdt.png';
import litecoin from '../assets/crypto/litecoin.png';
import payeer from '../assets/crypto/payeer.png';
import tron from '../assets/crypto/tron.png';
import balance from '../assets/crypto/balance.png';

const coins = [
    {img: usdt, name: 'USDT TRC20'},
    {img: bitcoin, name: 'Bitcoin'},
    {img: binance, name: 'Binance coin'},
    {img: binance, name: 'Binance usdt(bep2)'},
    {img: busd, name: 'BUSD (bep20)'},
    {img: litecoin, name: 'Litecoin'},
    {img: ethereum, name: 'Ethereum'},
    {img: tron, name: 'Tron (trx)'},
    {img: payeer, name: 'Payeer'},
    {img: balance, name: 'Balance'},
];

export const wallets = [
    {img: usdt, name: 'USDT TRC20', wallet: 'TPZQdJU7cHhuRdGk3k4QXtATanRfuGAJSz'},
    {img: bitcoin, name: 'Bitcoin', wallet: '1GF9bFgReFuGQx8BQaKH7N3gELJMWFYWgn'},
    {img: binance, name: 'Binance coin', wallet: '0x0Cf778dcAcbFD691f070782a1F7Df7Db8e4A0FF9'},
    {img: binance, name: 'Binance usdt(bep2)', wallet: '0x0Cf778dcAcbFD691f070782a1F7Df7Db8e4A0FF9'},
    {img: busd, name: 'BUSD (bep20)', wallet: '0x0Cf778dcAcbFD691f070782a1F7Df7Db8e4A0FF9'},
    {img: litecoin, name: 'Litecoin', wallet: 'ltc1qz4484hxp62p6vvygrr4uatcsd8mrj93muxa4s0'},
    {img: ethereum, name: 'Ethereum', wallet: '0x0Cf778dcAcbFD691f070782a1F7Df7Db8e4A0FF9'},
    {img: tron, name: 'Tron (trx)', wallet: 'TPZQdJU7cHhuRdGk3k4QXtATanRfuGAJSz'},
    {img: payeer, name: 'Payeer', wallet: 'P1105731032'},
];

export default coins