import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

function FaqItem({question, answer}) {
    const [showAnswer, setShowAnswer] = useState(false);

    return (
        <div className='w-full bg-white rounded-md p-3' onClick={() => setShowAnswer(prev => !prev)}>
            <div className='flex items-center gap-5'>
                <p className='font-medium text-black/80 flex-1'>{question}</p>
                <div className='h-[40px] w-[40px] rounded-full bg-[#f5f5f5] flex items-center justify-center'>
                    {showAnswer ? <FiChevronUp /> : <FiChevronDown />}
                </div>
            </div>
            
            {showAnswer && (
                <p className='text-black/70 mt-3'>{answer}</p>
            )}
        </div>
    )
}

export default FaqItem