import React from 'react';

//styles
import classes from './transactionitem.module.css';

//images
import bitcoin from '../../assets/crypto/bitcoin.png';
import ethereum from '../../assets/crypto/ethereum.png';
import binance from '../../assets/crypto/binance.png';
import litecoin from '../../assets/crypto/litecoin.png';
import payeer from '../../assets/crypto/payeer.png';
import busd from '../../assets/crypto/busd.svg';
import usdt from '../../assets/crypto/usdt.png';

function TransactionItem({sn, type, name, amount, status, date, onSelect}) {
    
    let status_container = <div className={classes.pending}>{status}</div>;
    if(status === 'failed' || status === "declined"){
        status_container = <div className={classes.failed}>{status}</div>
    }else if(status === 'success'){
        status_container = <div className={classes.success}>{status}</div>
    };


    return (
        <div className={'border-b items-center border-[#c4c4c4] px-[20px] gap-[20px] grid py-5 grid-cols-[30px_200px_100px_100px_100px_100px] md:grid-cols-[30px_1fr_150px_150px_150px_150px]'} onClick={onSelect}>
            <div>{sn}</div>
            <div className={classes.transaction}>{name}</div>
            <div>{type}</div>
            <div>${amount.toLocaleString()}</div>
            {status_container}
            <div className='hidden md:flex'>{date}</div>
        </div>
    )
}

export default TransactionItem